.view_home {
  &__container {
    max-width: 700px;
    min-height: 180px;
    margin: 0 auto;
    position: relative;
    padding: 0.5rem 1rem 1rem 1rem;
    background-color: var(--bg2);
    color: var(--text1);

    &__left {
      float: left;
      position: relative;
      height: 6rem;
      width: 100%;
      @media screen and (min-width: 470px) {
        height: 7.5rem;
        width: 45%;
      }
    }

    label {
      display: block;
      margin-top: 0.2rem;
    }

    input {
      display: block;
      width: 10rem;
      margin-top: 0.2rem;
      padding: 0.3rem 0.5rem;
      font-size: 1rem;
      background-color: var(--bg3);
      border: none;
      color: var(--text1);
    }

    select {
      position: absolute;
      top: 1.72rem;
      left: 10.5rem;
      padding: 0.24rem;
      background-color: var(--bg3);
      border: none;
      color: var(--text1);
      font-size: 1rem;
    }
    option {
      background-color: var(--bg2);
    }

    button {
      width: 10rem;
      margin: 0.5rem 0 0 0;
      padding: 0.2rem 0 0.25rem 0;
      color: var(--text2);
      border: none;
      font-size: 1rem;
      &:not(:disabled),
      &:not([disabled]) {
        background-color: var(--button);
      }
      &:hover {
        cursor: pointer;
      }
      &:disabled,
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }

  &__settingsicon {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    color: var(--icon1);
    font-size: 2rem;
    &:hover {
      cursor: pointer;
    }
  }

  &__error {
    margin-bottom: -0.3rem;
    color: var(--error);
    font-style: italic;
    font-weight: bold;
    letter-spacing: 0.05rem;
  }
}

.view_settings {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: var(--bg2);
  color: var(--text1);

  &__closeicon {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    font-size: 2rem;
    color: var(--icon1);
    &:hover {
      cursor: pointer;
    }
  }

  label {
    display: block;
    margin-top: 0.5rem;
  }

  input {
    display: block;
    background-color: rgba(0, 0, 0, 0.281);
    border: 0.1rem solid black;
    border-radius: 0.3rem;
    padding: 0.15rem 0.3rem;
    width: 100%;
    color: var(--text1);
    font-size: 0.9rem;
  }

  &__theme {
    border: 0.1rem black;
    border-style: none none solid none;
    padding: 0.15rem 0.3rem 0.15rem 0.3rem;
    margin: 0.5rem 0;
    position: relative;

    &__selected {
      position: absolute;
      left: 9rem;
      top: 0.15rem;
      background-color: var(--bg1);
      color: var(--text2);
      font-size: 0.8rem;
      padding: 0.08rem 0.5rem 0.15rem 0.5rem;
      border-radius: 0.3rem;
    }
  }
}
