* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: background-color 300ms ease;
}

:root {
  --bg1: rgb(27, 27, 27);
  --bg2: rgb(42 74 47);
  --bg3: rgba(0, 0, 0, 0.37);
  --text1: rgb(221, 221, 221);
  --text2: rgb(221, 221, 221);
  --icon1: rgb(216, 140, 0);
  --ok: #0ba309;
  --error: rgb(255 57 57);
  --button: rgb(49 114 0);
}

html,
body,
#root {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: inherit;
}

main {
  min-height: 100%;
  padding: 1rem;
  background-color: var(--bg1);
}

@mixin pc {
  @media (min-width: 801px) {
    @content;
  }
}

@import 'components';
@import 'utils';
@import 'view';

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
