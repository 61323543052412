.comp_history {
  summary {
    color: var(--icon1);
    font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 0.3rem;
    &:hover {
      cursor: pointer;
    }
  }

  table {
    border-collapse: collapse;
    th,
    td {
      background-color: var(--bg3);
      border: 0.2rem solid var(--bg2);
      min-width: 5rem;
      text-align: left;
      padding: 0.1rem 0.5rem 0.2rem 0.5rem;
    }
  }
}
.comp_newpp {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  &--smalltext {
    font-size: 0.9rem;
    opacity: 0.8;
    summary:hover {
      cursor: pointer;
    }
  }

  &__box {
    position: relative;
    background-color: var(--bg3);
    padding: 0.5rem;
    width: 100%;
    @media screen and (min-width: 470px) {
      width: calc(50% - 0.6rem);
    }

    &__nr {
      position: absolute;
      opacity: 0.5;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

.comp_firstres {
  float: right;
  width: 100%;
  height: 8rem;
  padding-top: 0.7rem;
  @media screen and (min-width: 470px) {
    width: 54%;
    height: 7.5rem;
    padding-top: 1rem;
  }

  &__copy {
    color: var(--ok);
    margin-top: 0.3rem;
    font-style: italic;
  }
}
