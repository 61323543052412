* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  transition: background-color 300ms ease;
}

:root {
  --bg1: rgb(27, 27, 27);
  --bg2: rgb(42 74 47);
  --bg3: rgba(0, 0, 0, 0.37);
  --text1: rgb(221, 221, 221);
  --text2: rgb(221, 221, 221);
  --icon1: rgb(216, 140, 0);
  --ok: #0ba309;
  --error: rgb(255 57 57);
  --button: rgb(49 114 0);
}

html,
body,
#root {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: inherit;
}

main {
  min-height: 100%;
  padding: 1rem;
  background-color: var(--bg1);
}

.comp_history summary {
  color: var(--icon1);
  font-size: 1.1rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
}
.comp_history summary:hover {
  cursor: pointer;
}
.comp_history table {
  border-collapse: collapse;
}
.comp_history table th,
.comp_history table td {
  background-color: var(--bg3);
  border: 0.2rem solid var(--bg2);
  min-width: 5rem;
  text-align: left;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
}

.comp_newpp {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.comp_newpp--smalltext {
  font-size: 0.9rem;
  opacity: 0.8;
}
.comp_newpp--smalltext summary:hover {
  cursor: pointer;
}
.comp_newpp__box {
  position: relative;
  background-color: var(--bg3);
  padding: 0.5rem;
  width: 100%;
}
@media screen and (min-width: 470px) {
  .comp_newpp__box {
    width: calc(50% - 0.6rem);
  }
}
.comp_newpp__box__nr {
  position: absolute;
  opacity: 0.5;
  top: 0.5rem;
  right: 0.5rem;
}

.comp_firstres {
  float: right;
  width: 100%;
  height: 8rem;
  padding-top: 0.7rem;
}
@media screen and (min-width: 470px) {
  .comp_firstres {
    width: 54%;
    height: 7.5rem;
    padding-top: 1rem;
  }
}
.comp_firstres__copy {
  color: var(--ok);
  margin-top: 0.3rem;
  font-style: italic;
}

.view_home__container {
  max-width: 700px;
  min-height: 180px;
  margin: 0 auto;
  position: relative;
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: var(--bg2);
  color: var(--text1);
}
.view_home__container__left {
  float: left;
  position: relative;
  height: 6rem;
  width: 100%;
}
@media screen and (min-width: 470px) {
  .view_home__container__left {
    height: 7.5rem;
    width: 45%;
  }
}
.view_home__container label {
  display: block;
  margin-top: 0.2rem;
}
.view_home__container input {
  display: block;
  width: 10rem;
  margin-top: 0.2rem;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  background-color: var(--bg3);
  border: none;
  color: var(--text1);
}
.view_home__container select {
  position: absolute;
  top: 1.72rem;
  left: 10.5rem;
  padding: 0.24rem;
  background-color: var(--bg3);
  border: none;
  color: var(--text1);
  font-size: 1rem;
}
.view_home__container option {
  background-color: var(--bg2);
}
.view_home__container button {
  width: 10rem;
  margin: 0.5rem 0 0 0;
  padding: 0.2rem 0 0.25rem 0;
  color: var(--text2);
  border: none;
  font-size: 1rem;
}
.view_home__container button:not(:disabled), .view_home__container button:not([disabled]) {
  background-color: var(--button);
}
.view_home__container button:hover {
  cursor: pointer;
}
.view_home__container button:disabled, .view_home__container button[disabled] {
  cursor: not-allowed;
}
.view_home__settingsicon {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  color: var(--icon1);
  font-size: 2rem;
}
.view_home__settingsicon:hover {
  cursor: pointer;
}
.view_home__error {
  margin-bottom: -0.3rem;
  color: var(--error);
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
}

.view_settings {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: var(--bg2);
  color: var(--text1);
}
.view_settings__closeicon {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  font-size: 2rem;
  color: var(--icon1);
}
.view_settings__closeicon:hover {
  cursor: pointer;
}
.view_settings label {
  display: block;
  margin-top: 0.5rem;
}
.view_settings input {
  display: block;
  background-color: rgba(0, 0, 0, 0.281);
  border: 0.1rem solid black;
  border-radius: 0.3rem;
  padding: 0.15rem 0.3rem;
  width: 100%;
  color: var(--text1);
  font-size: 0.9rem;
}
.view_settings__theme {
  border: 0.1rem black;
  border-style: none none solid none;
  padding: 0.15rem 0.3rem 0.15rem 0.3rem;
  margin: 0.5rem 0;
  position: relative;
}
.view_settings__theme__selected {
  position: absolute;
  left: 9rem;
  top: 0.15rem;
  background-color: var(--bg1);
  color: var(--text2);
  font-size: 0.8rem;
  padding: 0.08rem 0.5rem 0.15rem 0.5rem;
  border-radius: 0.3rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}/*# sourceMappingURL=main.css.map */